.circle-button {
    border: 0.2rem solid var(--text-primary);
    color: var(--text-primary);
    background: var(--bg-primary);
    border-radius: 50%;
    cursor: pointer;
    display: inline-flex;
    margin: 0.5rem;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    transition: all 0.5s;

    &:hover {
        color: var(--bg-primary);
        background: var(--text-primary);
    }
}

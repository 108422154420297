.top-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .intro {
        text-align: center;

        h1 {
            font-size: 9rem;
            font-weight: bold;
            line-height: 1.1em;
            margin: 2rem;
        }

        p {
            font-size: 1.5rem;
            margin: 0;
        }

        h4 {
            font-style: italic;
            margin-top: 0.7rem;
            font-size: 1.1rem;
        }

        a {
            text-decoration: underline; /* removes the underline */
          }

        a:hover {
            text-decoration: none; /* underlines the text when the mouse hovers over it */
            font-weight: 900;
        }
    }

    .social {
        position: absolute;
        bottom: 3rem;
        margin: 0 auto;
        text-align: center;
    }
}

@media screen and (max-width: 1300px) {
    .top-section {

        .intro {
            width: 50%;

            h1 {
                font-size: 7rem;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .top-section {
        flex-direction: column;

        .intro {
            text-align: center;
            border-bottom: 1px solid var(--text-primary);
            border-right: none;
            width: auto;
            padding: 0;
            padding-bottom: 2rem;

            h1 {
                font-size: 5rem;
            }
        }

        .signature {
            height: 30%;
            padding: 0;
            padding-top: 2rem;
        }
    }
}

@keyframes drawSignature {
    to {
        stroke-dashoffset: 0;
    }
}

.about-section {

    h4 {
        font-size: 2rem;
    }

    li {
        @media (max-width: 768px) {
          list-style: none;
        }
    }

    a {
        font-weight: 800;
    }

    .programming-icons {

        svg {
            // margin: 5rem, 5rem, 5rem, 5rem;
            margin-right: 1rem;
            margin-left: 1rem;
            width: 5rem;
            height: 5rem;

            // &:first-child {
            //     margin-left: 0;
            // }
        }
    }
}
